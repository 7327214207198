import {useRef} from "react";
import {useQuery} from "react-query";

export const useCheckAppVersion = () => {
    const versionWasLogged = useRef(false);
    const currentMainHashRef = useRef('');
    const currentSubHashesRef = useRef('');
    const currentVersion = document.head.querySelector('[name="app-version"]')?.content;

    const {isLoading, error, data} = useQuery('indexPage', async () => {
        const res = await fetch('/');
        return await res.text();
    });

    const mainChunkRegEx = new RegExp(/<script src="\/static\/js\/main\.?(.*)\.chunk\.js">/);
    const subChunkRegEx = new RegExp(/return .\..\+"static\/js\/"\+\({}\[.]\|\|.\)\+"\."\+(.*)\[.]\+"\.chunk\.js"/);

    if (!currentMainHashRef.current) {
        currentMainHashRef.current = mainChunkRegEx.exec(document.body.innerHTML)?.[1];
    }
    if (!currentSubHashesRef.current) {
        currentSubHashesRef.current = subChunkRegEx.exec(document.body.innerHTML)?.[1];
    }
    const latestMainChunkHash = mainChunkRegEx.exec(data)?.[1];
    const latestSubChunkHashes = subChunkRegEx.exec(data)?.[1];

    const completedLoading = !isLoading && !error && currentMainHashRef.current && currentSubHashesRef.current && latestMainChunkHash && latestSubChunkHashes;
    if (completedLoading && !versionWasLogged.current) {
        console.log('App Version', currentVersion);
        console.log('Main Hash', currentMainHashRef.current);
        console.log('Sub Hashes', currentSubHashesRef.current);
        versionWasLogged.current = true;
    }
    if (completedLoading && (currentMainHashRef.current !== latestMainChunkHash || currentSubHashesRef.current !== latestSubChunkHashes)) {
        // window.location.reload();
    }

    return {currentVersion, currentMainHashRef, currentSubHashesRef};
};