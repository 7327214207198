import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import * as fas from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const StyledModal = styled(Modal)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  z-index: 10000;
  border: 1px solid black;
  outline: none;
  overflow: auto;
  max-height: 95vh;
  max-width: 95vw;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 5px;
  right: 10px;
  color: ${({closeButtonColor}) => closeButtonColor ?? '#888888'};
  cursor: pointer;
  font-size: 18px;
  z-index: 10002;

  &:hover {
    color: ${({closeButtonHoverColor}) => closeButtonHoverColor ?? '#444444'};
  }
`;

export const useModal = () => {
    const [isOpen, setIsOpen] = useState();
    const [content, setContent] = useState();
    const [closeButtonColor, setCloseButtonColor] = useState();
    const [closeButtonHoverColor, setCloseButtonHoverColor] = useState();

    useEffect(() => {
        const onKeyup = e => {
            if (e.key === 'Escape') {
                setIsOpen(false);
            }
        };
        window.addEventListener('keyup', onKeyup);
        return () => window.removeEventListener('keyup', onKeyup);
    }, []);

    const openModal = useCallback(({content, closeButtonColor, closeButtonHoverColor}) => {
        setContent(content);
        setCloseButtonColor(closeButtonColor);
        setCloseButtonHoverColor(closeButtonHoverColor);
        setIsOpen(true);
    }, []);

    const closeModal = useCallback(() => setIsOpen(false), []);

    const modalComponent = useMemo(() => (
        <StyledModal
            ariaHideApp={false}  //See: https://stackoverflow.com/a/50625073/5515589
            style={{
                overlay: {
                    zIndex: 10000,
                },
            }}
            isOpen={isOpen}
        >
            <CloseButton onClick={closeModal} closeButtonColor={closeButtonColor}
                         closeButtonHoverColor={closeButtonHoverColor}>
                <FontAwesomeIcon icon={fas.faTimes}/>
            </CloseButton>
            <div style={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                {content}
            </div>
        </StyledModal>
    ), [isOpen, content, closeButtonColor, closeButtonHoverColor]);

    return {modalComponent, openModal, closeModal};
};