import React, {createContext, lazy, Suspense, useReducer, useState} from 'react';
import Amplify from 'aws-amplify';
import {QueryClient, QueryClientProvider} from "react-query";
import produce from "immer";
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";
import {ReactQueryDevtools} from 'react-query/devtools';
import styled from 'styled-components';
import Header from "./Components/Header";
import {Redirect} from "react-router";
import "filepond/dist/filepond.min.css";
import "react-day-picker/lib/style.css";
import Footer from "./Components/Footer";
import {useModal} from "./hooks/useModal";
import {useOverlay} from "./hooks/useOverlay";
import {useAuth} from "./hooks/useAuth";
import ReactTooltip from 'react-tooltip';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as fas from "@fortawesome/pro-solid-svg-icons";
import * as fal from "@fortawesome/pro-light-svg-icons";
import {LoadingOverlay, mediumGreen, mediumPurple, mediumYellow} from "./Components/BaseComponents";
import './i18n/i18n';
import {useTranslation} from "react-i18next";

// import OrganizationApproval from "./Pages/OrganizationApproval";
// import StudentApproval from "./Pages/StudentApproval";
// import ListOrganizations from "./Pages/ListOrganizations";
// import {TransactionManagement} from "./Pages/TransactionManagement";
// import {ReshutUchlusin} from "./Pages/ReshutUchlusin";
// import Registration from "./Pages/Registration";
// import Login from "./Pages/Login";
// import AddStudents from "./Pages/AddStudents";
// import LandingPage from "./Pages/LandingPage";
// import ChangePassword from "./Pages/ChangePassword";
// import ForgotPassword from "./Pages/ForgotPassword";
// import ListStudents from "./Pages/ListStudents";

const OrganizationApproval = lazy(() => import('./Pages/OrganizationApproval'));
const ListOrganizations = lazy(() => import('./Pages/ListOrganizations'));
const ReshutUchlusin = lazy(() => import('./Pages/ReshutUchlusin'));
const StudentApproval = lazy(() => import('./Pages/StudentApproval'));
const AddStudents = lazy(() => import('./Pages/AddStudents'));
const ListStudents = lazy(() => import('./Pages/ListStudents'));
const TransactionManagement = lazy(() => import('./Pages/TransactionManagement'));
const NewsManagement = lazy(() => import('./Pages/NewsManagement'));
const ChangePassword = lazy(() => import('./Pages/ChangePassword'));
const ForgotPassword = lazy(() => import('./Pages/ForgotPassword'));
const Registration = lazy(() => import('./Pages/Registration'));
const Login = lazy(() => import('./Pages/Login'));
const LandingPage = lazy(() => import('./Pages/LandingPage'));

Amplify.configure({
    aws_cognito_region: "eu-west-2",
    aws_user_pools_id: "eu-west-2_SdDKBBS4C",
    aws_user_pools_web_client_id: "7lcr3ot1br68atlitbj41rrtgg",
});

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            // refetchOnWindowFocus: false,
            // refetchOnMount: false,
            // refetchOnReconnect: false,
            // retry: false,
            // staleTime: twentyFourHoursInMs,
        },
    },
});

export const AppContext = createContext();
export const AppFunctionsContext = createContext();

const ReactTooltipContainer = styled.div`
  .react-tooltip {
    z-index: 20000;
    font-size: 16px;
  }
`;

const StyledReactTooltip = styled(ReactTooltip)`
  max-width: 30vw;
  overflow-wrap: break-word;
  white-space: normal;
`;

const App = () => {
    const {modalComponent, openModal, closeModal} = useModal();
    const {overlayComponent, addToOverlaySet, removeFromOverlaySet} = useOverlay();
    const [firstLogin, setFirstLogin] = useState(false);

    return (
        <QueryClientProvider client={queryClient}>
            <AppContext.Provider value={useReducer(produce, {})}>
                <AppFunctionsContext.Provider
                    value={{openModal, addToOverlaySet, removeFromOverlaySet, closeModal, setFirstLogin}}
                >
                    <Routes firstLogin={firstLogin}/>
                    {modalComponent}
                    {overlayComponent}
                    <ReactTooltipContainer>
                        <StyledReactTooltip
                            multiline={true}
                            delayShow={200}
                            className={'react-tooltip'}
                        />
                    </ReactTooltipContainer>
                    <ToastContainer
                        hideProgressBar={true}
                        position={"bottom-center"}
                        style={{zIndex: 20000}}
                        toastStyle={{zIndex: 20000}}
                        bodyStyle={{zIndex: 20000}}
                    />
                    <ReactQueryDevtools/>
                </AppFunctionsContext.Provider>
            </AppContext.Provider>
        </QueryClientProvider>
    );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
`;

const InnerContentWrapper = styled.div`
  overflow: auto;
  height: 100%;
  padding: 20px;

  //position: absolute;
  // background: #FFFFFF 0% 0% no-repeat padding-box;
  // box-shadow: 0px 3px 6px #00000029;

  //display: flex;
  //flex-direction: row;
  //justify-content: center;
  //align-items: center;

  //background-size: 10px 10px;
  //background-image: linear-gradient(to right, #f0f0f0 1px, transparent 1px),
  //linear-gradient(to bottom, #f0f0f0 1px, transparent 1px);
`;

const BackgroundComponent = () => (
    <>
        <div style={{
            position: 'fixed',
            bottom: '-100px',
            right: '-100px',
            color: `${mediumGreen}10`,
            fontSize: '700px',
            zIndex: '-1',
        }}>
            <FontAwesomeIcon icon={fas.faVirus}/>
        </div>
        <div style={{
            position: 'fixed',
            top: '0',
            left: '0',
            color: `${mediumPurple}10`,
            fontSize: '700px',
            zIndex: '-1',
        }}>
            <FontAwesomeIcon icon={fal.faVirus}/>
        </div>
        <div style={{
            position: 'fixed',
            bottom: '20px',
            left: '20px',
            color: `${mediumYellow}20`,
            fontSize: '300px',
            zIndex: '-1',
        }}>
            <FontAwesomeIcon icon={fal.faClipboardCheck}/>
        </div>
        <div style={{
            position: 'fixed',
            top: '110px',
            right: '30px',
            color: `rgba(0, 9, 172, 0.06)`,
            fontSize: '300px',
            zIndex: '-1',
        }}>
            <FontAwesomeIcon icon={fal.faPassport}/>
        </div>
    </>
);

const Routes = ({firstLogin}) => {
    const {t, i18n} = useTranslation();

    const {
        loggedIn,
        canImportAndExport,
        canApproveOrganizations,
        canListOrganizations,
        canApproveStudents,
        canAddStudents,
        canAddNews,
        loginStatusChecked,
        canListStudents,
        canAddTransactions,
    } = useAuth();
    if (!loginStatusChecked) return <></>; //Don't run the logic below which will cause a redirect to the home page until we get the user's roles (if they are logged in)

    return (
        <Router>
            <ContentWrapper style={{direction: i18n.dir()}}>
                <Header firstLogin={firstLogin}/>
                <InnerContentWrapper>
                    <BackgroundComponent/>
                    <Suspense fallback={<LoadingOverlay/>}>
                        <Switch>
                            {canApproveOrganizations &&
                            <Route path="/organizations/approve">
                                <OrganizationApproval/>
                            </Route>
                            }
                            {canListOrganizations &&
                            <Route path="/organizations/list">
                                <ListOrganizations/>
                            </Route>
                            }
                            {canImportAndExport &&
                            <Route path="/reshutuchlusin">
                                <ReshutUchlusin/>
                            </Route>
                            }
                            {canApproveStudents &&
                            <Route path="/students/approve">
                                <StudentApproval/>
                            </Route>
                            }
                            {canAddStudents &&
                            <Route path="/students/add">
                                <AddStudents/>
                            </Route>
                            }
                            {canListStudents &&
                            <Route path="/students/list">
                                <ListStudents/>
                            </Route>
                            }
                            {canAddTransactions &&
                            <Route path="/transactions">
                                <TransactionManagement/>
                            </Route>
                            }
                            {canAddNews &&
                            <Route path="/news/manage">
                                <NewsManagement/>
                            </Route>}
                            {(firstLogin || loggedIn) &&
                            <Route path="/password/change">
                                <ChangePassword/>
                            </Route>
                            }
                            {(!loggedIn) &&
                            <Route path="/password/reset">
                                <ForgotPassword/>
                            </Route>
                            }
                            {!loggedIn &&
                            <Route path="/register">
                                <Registration/>
                            </Route>
                            }
                            <Route path="/login">
                                <Login/>
                            </Route>
                            <Route path="/" exact={true}>
                                <LandingPage/>
                            </Route>
                            <Redirect to="/"/>
                        </Switch>
                    </Suspense>
                </InnerContentWrapper>
                <Footer/>
            </ContentWrapper>
        </Router>
    );
};

export default App;