import {Auth, Hub, Logger} from "aws-amplify";
import {AppFunctionsContext, queryClient} from '../App';
import {useQuery} from "react-query";
import _ from 'lodash';
import {useContext, useEffect, useMemo, useState} from "react";
import {useHistory} from "react-router-dom";

export const useAuth = () => {
    const {isShowingOverlay} = useContext(AppFunctionsContext);
    const [loginStatusChecked, setLoginStatusChecked] = useState(false);
    const [justLoggedIn, setJustLoggedIn] = useState(false);
    const history = useHistory();

    const {data: user} = useQuery("currentUser", async () => {
        try {
            return await Auth.currentAuthenticatedUser();
        } catch (e) {

        }
        return;
    }, {
        onSettled: () => {
            setLoginStatusChecked(true);
        }
    });

    const getUserRoles = useMemo(() => {
        const roles = user?.signInUserSession?.accessToken?.payload['cognito:groups'] || [];

        const isAdmin = roles.includes("Admin");
        const isStaff = roles.includes("Admin") || roles.includes("Staff");
        const isOrganization = roles.includes("Organizations");

        const canApproveOrganizations = isAdmin;
        const canListOrganizations = isStaff;
        const canApproveStudents = isStaff;
        const canAddStudents = isOrganization;
        const canListStudents = isOrganization;
        const canAddNews = isStaff;
        const canImportAndExport = isAdmin;
        const canAddTransactions = isStaff;

        return {
            roles,
            isAdmin,
            canApproveOrganizations,
            canListOrganizations,
            canApproveStudents,
            canAddStudents,
            canAddNews,
            canImportAndExport,
            canListStudents,
            canAddTransactions,
            isOrganization,
        }
    }, [user]);

    useEffect(() => {
        if (justLoggedIn) {
            const {
                canApproveOrganizations,
                canListOrganizations,
                canApproveStudents,
                canListStudents,
            } = getUserRoles;

            const defaultRoute = canApproveOrganizations ? "/approve/organizations" : canListOrganizations ? "/list/organizations" : canApproveStudents ? "/approve/students" : canListStudents ? "/list/students" : "/";
            history.push(defaultRoute);
            setJustLoggedIn(false);
        }
    }, [justLoggedIn]);

    return useMemo(() => {
        const email = user?.attributes?.email;


        return {
            loggedIn: !_.isNil(user),
            user,
            ...getUserRoles,
            loginStatusChecked,
            email,
            setJustLoggedIn,
        };
    }, [user, loginStatusChecked, isShowingOverlay]);
}

const logger = new Logger('My-Logger');

const listener = (data) => {
    switch (data.payload.event) {
        case 'signIn':
            logger.info('user signed in');
            queryClient.setQueryData("currentUser", data.payload.data);
            break;
        case 'signUp':
            logger.info('user signed up');
            break;
        case 'signOut':
            logger.info('user signed out');
            queryClient.setQueryData("currentUser", undefined);
            break;
        case 'signIn_failure':
            logger.error('user sign in failed');
            break;
        case 'tokenRefresh':
            logger.info('token refresh succeeded');
            break;
        case 'tokenRefresh_failure':
            logger.error('token refresh failed');
            break;
        case 'configured':
            logger.info('the Auth module is configured');
    }
}

Hub.listen('auth', listener);