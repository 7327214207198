import styled from "styled-components";
import * as fas from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useContext, useEffect, useRef} from "react";
import {AppFunctionsContext} from "../App";

export const veryLightYellow = '#FFF7E5';
export const lightYellow = '#E7D8B8';
export const mediumYellow = '#C2AF84';
export const darkYellow = '#A38D5C';
export const veryDarkYellow = '#7E6938';

export const touchOfPurple = '#efeef2';
export const touchMoreOfPurple = '#e8e6eb';
export const veryLightPurple = '#B4AFC0';
export const lightPurple = '#8A819E';
export const mediumPurple = '#6A5F85';
export const darkPurple = '#51446F';
export const veryDarkPurple = '#382B56';

export const veryLightGreen = '#A1B0B3';
export const lightGreen = '#72898F';
export const mediumGreen = '#537178';
export const darkGreen = '#3A5D65';
export const veryDarkGreen = '#24464E';

export const veryLightRed = '#e7b8b8';
export const veryLightOrange = '#e7ccb8';

export const femaleColor = '#ff81e5';
export const maleColor = '#7e7eff';

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const Heading = styled.div`
  font-size: 43px;
  padding: 10px 0;
  text-align: center;
`;

export const InformationMessage = styled.div`
  color: ${mediumPurple};
  font-weight: bold;
`;

export const FieldsWrapper = styled.div`
  display: grid;
  justify-items: center;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px 10px;
  margin: auto;
  padding: 10px 0;
  box-sizing: border-box;
`;

export const FieldGroupLabel = styled.div`
  margin-top: 10px;
  align-self: start;
  font-size: larger;
  font-weight: 800;
`;

export const LoadingIcon = () => <FontAwesomeIcon style={{color: mediumPurple}} icon={fas.faCog} spin/>;
export const LoadingOverlay = () => {
    const {addToOverlaySet, removeFromOverlaySet} = useContext(AppFunctionsContext);

    useEffect(() => {
        const id = "loading-overlay";
        addToOverlaySet(id)
        return () => removeFromOverlaySet(id)
    }, [])
    return <div></div>
}

//States: 1 = all. 0 = none. -1 = some
export const IndeterminateCheckbox = ({label, state, onToggle}) => {
    const ref = useRef()
    useEffect(() => {
        if (ref.current) {
            ref.current.indeterminate = state === -1;
        }
    }, [state, ref.current])
    return <div>{label && <div>{label}</div>}
        <div><input ref={ref} type={'checkbox'} checked={state === 1} onChange={onToggle}/></div>
    </div>
}