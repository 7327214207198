import React, {useEffect, useMemo, useRef} from 'react';
import InputMask from "react-input-mask";
import {ErrorMessage, Field, useFormikContext} from 'formik';
import dateFnsParse from 'date-fns/parse';
import styled from 'styled-components';
import Switch from "react-switch";
import Select from "react-select";
import {FilePond, registerPlugin} from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {darkYellow, lightPurple, mediumYellow, veryDarkYellow} from "./BaseComponents";
import _ from 'lodash';
import * as fas from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateSize, FilePondPluginFileValidateType);
const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png",
    "application/pdf",
];

const InputOuterWrapper = styled.div`
  //padding: 10px;
  width: 100%;
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  &:focus-within label {
    transform: translate(0, 14px) scale(0.8);
    color: ${veryDarkYellow};
  }

  & label.filled {
    transform: translate(0, 14px) scale(0.8);
  }
`;

const TextWrapperDiv = styled.div`
  position: relative;
  border: 1px solid ${darkYellow};
  border-radius: 5px;
  background: white;
  padding: 20px 10px 6px 10px;
  min-height: 18px;
`;

const StyledLabel = styled.label`
  position: absolute;
  pointer-events: none;
  transform: translate(0, 23px) scale(1);
  transform-origin: top ${({isRtl}) => isRtl ? 'right' : 'left'};
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: ${darkYellow};
  font-size: 16px;
  line-height: 1;
  top: -8px;
  padding: ${({isRtl}) => isRtl ? '0 14px 0 5px' : '0 5px 0 14px'};
  z-index: 1;
`;

const StyledField = styled(Field)`
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 16px;
  border: 1px solid ${darkYellow};
  border-radius: 5px;
  padding: 20px 10px 6px 10px;
  outline: none;
  box-shadow: none;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

  &:focus {
    box-shadow: 0 0 0 2px ${mediumYellow};
  }

  &.error {
    border-color: red;
    background: #ffeeee;
  }
`;

export const StyledInput = styled.input`
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 16px;
  border: 1px solid ${darkYellow};
  border-radius: 5px;
  padding: 5px 10px;
  outline: none;
  box-shadow: none;

  &:focus {
    box-shadow: 0 0 0 2px ${mediumYellow};
  }
`;

export const TextArea = styled(StyledField).attrs(props => ({
    as: 'textarea',
}))`
  height: 100px;
`;

export const StyledSelect = styled(Select).attrs(props => {
    const {styles = {}} = props || {};

    return ({
        classNamePrefix: 'react-select',
        styles: {
            ...styles,
            control: base => ({
                ...base,
                cursor: 'pointer',
                border: `1px solid ${darkYellow}`,
                ...styles.control?.(base),
            }),
            input: base => ({
                // ...base,
                ...styles.input?.(base),
            }),
            singleValue: base => ({
                // ...base,
                ...styles.singleValue?.(base),
            }),
            option: base => ({
                ...base,
                cursor: 'pointer',
                ...styles.option?.(base),
            }),
            menuPortal: base => ({
                ...base,
                zIndex: 10050,
                ...styles.menuPortal?.(base),
            }),
        },
        menuPortalTarget: document.body,
        menuPosition: 'fixed',
    });
})`
  color: black;

  &.error > div {
    border-color: red;
    background: #ffeeee;
  }

  & .react-select__control--is-focused {
    box-shadow: 0 0 0 2px ${mediumYellow};
  }

  & .react-select__control:hover {
    border-color: ${darkYellow};
  }
`;

const StyledInputMask = styled(InputMask)`
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 16px;
  border: 1px solid ${darkYellow};
  border-radius: 5px;
  padding: 20px 10px 6px 10px;
  outline: none;
  box-shadow: none;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

  &.error {
    border-color: red;
    background: #ffeeee;
  }

  &:focus {
    box-shadow: 0 0 0 2px ${mediumYellow};
  }
`;

export const SwitchWrapper = styled.div`
  background: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 44.67px;
  border: 1px solid ${darkYellow};
  border-radius: 5px;
`;

const FilePondWrapper = styled.div`
  width: 100%;
  text-align: center;

  .filepond--action-remove-item {
    cursor: pointer;
  }
`;

export const ErrorDiv = styled.div`
  color: red;
`;

export const UnderFieldLabel = styled.div`
  font-size: 16px;
  color: ${lightPurple};
  //padding: 0 5px;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  //padding: 0 5px;
`;

export const RedStar = () => <span style={{color: 'red', fontWeight: 'bold'}}>*</span>;

export const LabelledText = ({label, underFieldLabel, value}) => {
    const {t, i18n} = useTranslation();
    const isRtl = i18n.dir() === "rtl";

    return (
        <InputOuterWrapper>
            <InputWrapper>
                <TextWrapperDiv>
                    <StyledLabel isRtl={isRtl} style={{padding: '0'}} className={'filled'}>{label}</StyledLabel>
                    <div>{value}</div>
                </TextWrapperDiv>
                {underFieldLabel &&
                <UnderFieldLabel>{underFieldLabel}</UnderFieldLabel>
                }
            </InputWrapper>
        </InputOuterWrapper>
    );
};

export const InputField = ({label, underFieldLabel, type = "text", name}) => {
    const {
        values,
        errors,
        touched,
    } = useFormikContext();
    const {t, i18n} = useTranslation();
    const isRtl = i18n.dir() === "rtl";

    const hasValue = !!values?.[name];
    const hasError = !!errors?.[name] && !!touched?.[name];

    return (
        <InputOuterWrapper>
            <InputWrapper>
                <StyledLabel isRtl={isRtl} className={hasValue && 'filled'}>{label}</StyledLabel>
                <StyledField type={type} name={name} className={hasError && 'error'}/>
                <UnderFieldLabel>{underFieldLabel}</UnderFieldLabel>
                <StyledErrorMessage name={name} component={ErrorDiv}/>
            </InputWrapper>
        </InputOuterWrapper>
    );
};

export const TextareaField = ({label, name, underFieldLabel}) => {
    const {
        setFieldValue,
        values,
        errors,
        touched,
    } = useFormikContext();
    const {t, i18n} = useTranslation();
    const isRtl = i18n.dir() === "rtl";

    const hasValue = !!values?.[name];
    const hasError = !!errors?.[name] && !!touched?.[name];

    return (
        <InputOuterWrapper>
            <InputWrapper>
                <StyledLabel isRtl={isRtl} className={hasValue && 'filled'}>{label}</StyledLabel>
                <TextArea
                    className={hasError && 'error'}
                    value={values[name]}
                    onChange={(e) => setFieldValue(name, e.target.value)}
                />
                <UnderFieldLabel>{underFieldLabel}</UnderFieldLabel>
                <StyledErrorMessage name={name} component={ErrorDiv}/>
            </InputWrapper>
        </InputOuterWrapper>
    );
};

export const getDateStringFormat = value => (value || "").length === 8 ? "dd/MM/yy" : 'dd/MM/yyyy';

export const parseDateString = value => dateFnsParse(value, getDateStringFormat(value), new Date());

export const DateInputField = ({label, name, underFieldLabel}) => {
    const {
        setFieldValue,
        values,
        errors,
        touched,
    } = useFormikContext();
    const {t, i18n} = useTranslation();
    const isRtl = i18n.dir() === "rtl";

    const hasValue = !!values?.[name];
    const hasError = !!errors?.[name] && !!touched?.[name];

    //Make Formik formReset function work
    const ref = useRef();
    useEffect(() => {
        if (!ref.current) return;
        if (values[name] === undefined) {
            ref.current.value = "";
        } else {
            ref.current.value = values[name];

        }
    }, [values[name]]);

    return (
        <InputOuterWrapper>
            <InputWrapper>
                <StyledLabel isRtl={isRtl} className={hasValue && 'filled'}>{label}</StyledLabel>
                <StyledInputMask
                    ref={ref}
                    className={hasError && 'error'}
                    mask={[/[0-3]/, /[0-9]/, '/', /[0-1]/, /[0-9]/, '/', /[1-2]/, /[0-9]/, /[0-9]/, /[0-9]/]}
                    maskPlaceholder={"dd/mm/yyyy"}
                    onBlur={e => {
                        setFieldValue(name, e.target.value);
                    }}
                />
                <UnderFieldLabel>{underFieldLabel}</UnderFieldLabel>
                <StyledErrorMessage name={name} component={ErrorDiv}/>
            </InputWrapper>
        </InputOuterWrapper>
    );
};

export const SelectInputField = ({label, name, options, underFieldLabel}) => {
    const {
        setFieldValue,
        values,
        errors,
        touched,
    } = useFormikContext();
    const {t, i18n} = useTranslation();
    const isRtl = i18n.dir() === "rtl";

    const hasValue = !!values?.[name];
    const hasError = !!errors?.[name] && !!touched?.[name];
    const selected = useMemo(() => _.find(options, o => o.value === values[name]) || null /*null indicates to the select to clear the field*/, [options, values[name]]);

    return (
        <InputOuterWrapper>
            <InputWrapper>
                <StyledLabel isRtl={isRtl} className={hasValue && 'filled'}>{label}</StyledLabel>
                <StyledSelect
                    className={hasError && 'error'}
                    options={options}
                    placeholder=""
                    value={selected}
                    onChange={v => {
                        setFieldValue(name, v?.value);
                    }}
                    styles={{
                        valueContainer: base => ({
                            ...base,
                            padding: '20px 10px 6px 10px',
                        }),
                    }}
                />
                <UnderFieldLabel>{underFieldLabel}</UnderFieldLabel>
                <StyledErrorMessage name={name} component={ErrorDiv}/>
            </InputWrapper>
        </InputOuterWrapper>
    );
};

export const defaultUncheckedIcon = <FontAwesomeIcon icon={fas.faTimes} style={{color: 'white', fontWeight: 'bold'}}/>;
export const defaultCheckedIcon = <FontAwesomeIcon icon={fas.faCheck} style={{color: 'white', fontWeight: 'bold'}}/>;

export const BoolInputField = ({
                                   label,
                                   name,
                                   uncheckedIcon = defaultUncheckedIcon,
                                   checkedIcon = defaultCheckedIcon,
                                   onColor,
                                   offColor,
                               }) => {
    const {
        setFieldValue,
        values,
    } = useFormikContext();

    return (
        <InputOuterWrapper style={{width: '100%'}}>
            <SwitchWrapper>
                <label style={{padding: '10px'}}>{label}</label>
                <div style={{margin: '10px'}}>
                    <Switch
                        checked={values[name] || false}
                        onChange={(nextChecked) => setFieldValue(name, nextChecked)}
                        handleDiameter={20}
                        onColor={onColor}
                        offColor={offColor}
                        uncheckedIcon={uncheckedIcon && <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                fontSize: 20,
                                paddingRight: 2,
                            }}
                        >
                            {uncheckedIcon}
                        </div>}
                        checkedIcon={checkedIcon && <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                fontSize: 20,
                                paddingRight: 2,
                            }}
                        >
                            {checkedIcon}
                        </div>}
                    />
                </div>
            </SwitchWrapper>
            <StyledErrorMessage name={name} component={ErrorDiv}/>
        </InputOuterWrapper>
    );
};

export const FilePondField = ({label, name, maxFiles = 1}) => {
    const {
        setFieldValue, values,
    } = useFormikContext();

    const allowMultiple = maxFiles > 1;
    const fileRef = useRef();
    //Clear the field on unMount
    useEffect(() => {
        return () => {
            setFieldValue(name, null);
            fileRef.current = null;
        };
    }, []);
    useEffect(() => {
        if (values[name] === undefined) {
            fileRef.current = null;
            setFieldValue(name, null);
        }
    }, [values[name]]);

    return (
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <FilePondWrapper>
                <label style={{fontSize: '16px'}}>{label}</label>
                <FilePond
                    name={name}
                    allowMultiple={allowMultiple}
                    maxFiles={maxFiles}
                    files={fileRef.current}
                    onupdatefiles={fileItems => {
                        const files = fileItems.map(f => f.file);
                        fileRef.current = files;
                        setFieldValue(name, files);
                    }}
                    maxFileSize={"5MB"}
                    acceptedFileTypes={SUPPORTED_FORMATS}
                    labelFileTypeNotAllowed={'Invalid file type'}
                >
                </FilePond>
                <StyledErrorMessage name={name} component={ErrorDiv}/>
            </FilePondWrapper>
        </div>
    );
};