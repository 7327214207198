import React, {useCallback, useMemo, useRef, useState} from 'react';
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as fas from "@fortawesome/pro-solid-svg-icons";
import {darkYellow, veryLightYellow} from "../Components/BaseComponents";

const OverlayDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: ${veryLightYellow}44;
  color: ${darkYellow}88;
  content: " ";
  font-size: 70px;
  font-weight: bold;
  z-index: 10001;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const useOverlay = () => {
    const overlaySetRef = useRef(new Set())
    const [overlaySet, setOverlaySet] = useState(new Set());

    const addToOverlaySet = useCallback(value => {
        const newSet = new Set(overlaySetRef.current);
        newSet.add(value);
        setOverlaySet(newSet);
        overlaySetRef.current = newSet;
    }, [overlaySet]);

    const removeFromOverlaySet = useCallback(value => {
        const newSet = new Set(overlaySetRef.current);
        newSet.delete(value);
        setOverlaySet(newSet);
        overlaySetRef.current = newSet;
    }, [overlaySet]);

    const isShowingOverlay = useMemo(() => overlaySet.size > 0, [overlaySet]);

    const overlayComponent = (
        <>
            {overlaySet.size > 0 &&
            <OverlayDiv>
                <FontAwesomeIcon icon={fas.faCog} spin/>
            </OverlayDiv>
            }
        </>
    );

    return {overlayComponent, addToOverlaySet, removeFromOverlaySet, isShowingOverlay};
};