import React, {useCallback, useContext} from 'react';
import styled from 'styled-components';
import {Link, NavLink} from 'react-router-dom';
import icon from "../images/igud_yeshivos_icon_clear_background.png";
import {darkGreen, mediumYellow} from "./BaseComponents";
import {useAuth} from "../hooks/useAuth";
import {Auth} from "aws-amplify";
import {useTranslation} from "react-i18next";
import {StyledSelect} from "./Input";
import {AppFunctionsContext} from "../App";

const languageOptions = [
    {label: 'English', value: 'en'},
    {label: 'עברית', value: 'he'},
    {label: 'Français', value: 'fr'},
];

const StyledLanguageSelect = styled(StyledSelect)`
  & .react-select__control--is-focused {
    box-shadow: none;
  }
`;

const headerHeight = 100;

const HeaderWrapper = styled.div`
  background: white;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${headerHeight}px;
  text-align: center;
  vertical-align: center;
  box-shadow: 0px 3px 6px #00000029;
  z-index: 9999;
`;

const HeaderSideWrapper = styled.div`
  width: 360px;
  min-width: 220px;
`;

const HeaderCenterWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const OrganizationNameDiv = styled.div`
  padding-bottom: 5px;
  font-size: 25px;
  color: ${darkGreen};
  border-bottom: 1px solid ${darkGreen};
  cursor: default;
`;

const NavigationButtonsWrapper = styled.div`
  padding-top: 5px;
  display: flex;
  justify-content: center;
`;

const NavLinkWrapperDiv = styled.div`
  margin: 0 10px;
`;

const StyledActiveSpan = styled.span`
  font-weight: bold;
  color: ${darkGreen};
  border-bottom: 2px solid ${darkGreen};
  cursor: default;
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  font-weight: bold;
  color: ${mediumYellow};

  &:hover {
    color: ${darkGreen};
  }

  &.active {
    color: ${darkGreen};
    // text-decoration: underline;
    border-bottom: 2px solid ${darkGreen};
  }
`;

const StyledSignOut = styled.span`
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
  color: ${mediumYellow};

  &:hover {
    color: ${darkGreen};
  }
`;

const Header = ({firstLogin}) => {
    const {
        loggedIn,
        canApproveOrganizations,
        canListOrganizations,
        canApproveStudents,
        canAddStudents,
        canAddNews,
        email,
        canImportAndExport,
        canListStudents,
        canAddTransactions,
        isOrganization,
    } = useAuth();
    const {openModal, closeModal, addToOverlaySet, removeFromOverlaySet} = useContext(AppFunctionsContext);

    const {t, i18n} = useTranslation();
    const isRtl = i18n.dir() === "rtl";

    const changeLng = useCallback(async (lng) => {
        await i18n.changeLanguage(lng.value);
    }, [i18n]);

    return (
        <HeaderWrapper>
            <HeaderSideWrapper>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
                    <Link to="/">
                        <img
                            src={icon}
                            style={{width: `${headerHeight}px`, height: `${headerHeight}px`, objectFit: 'contain'}}
                            alt={t('header.igud_yeshivos_logo')}
                        />
                    </Link>
                    <StyledLanguageSelect
                        value={languageOptions.find(x => x.value === i18n.language)}
                        options={languageOptions}
                        onChange={changeLng}
                        styles={{
                            control: base => ({
                                border: 'none',
                            }),
                            indicatorSeparator: base => ({
                                ...base,
                                background: 'none',
                            }),
                        }}
                    />
                </div>
            </HeaderSideWrapper>
            <HeaderCenterWrapper>
                <OrganizationNameDiv>{t("header.slogan", "Igud Yeshivos and Seminaries Bnei Chul")}</OrganizationNameDiv>
                <NavigationButtonsWrapper>
                    <NavLinkWrapperDiv>
                        <StyledNavLink to="/" exact={true}>{t("header.home")}</StyledNavLink>
                    </NavLinkWrapperDiv>
                    {canApproveOrganizations &&
                    <NavLinkWrapperDiv>
                        <StyledNavLink to="/organizations/approve">{t("header.approve_mosdos")}</StyledNavLink>
                    </NavLinkWrapperDiv>
                    }
                    {canListOrganizations &&
                    <NavLinkWrapperDiv>
                        <StyledNavLink to="/organizations/list">{t("header.list_mosdos")}</StyledNavLink>
                    </NavLinkWrapperDiv>
                    }
                    {canAddStudents &&
                    <NavLinkWrapperDiv>
                        <StyledNavLink to="/students/add">{t("header.add_student")}</StyledNavLink>
                    </NavLinkWrapperDiv>
                    }
                    {canListStudents &&
                    <NavLinkWrapperDiv>
                        <StyledNavLink to="/students/list">{t("header.list_students")}</StyledNavLink>
                    </NavLinkWrapperDiv>
                    }
                    {canApproveStudents &&
                    <NavLinkWrapperDiv>
                        <StyledNavLink to="/students/approve">{t("header.approve_students")}</StyledNavLink>
                    </NavLinkWrapperDiv>
                    }
                    {canImportAndExport &&
                    <NavLinkWrapperDiv>
                        <StyledNavLink to="/reshutuchlusin">{t('header.reshut_uchlusin')}</StyledNavLink>
                    </NavLinkWrapperDiv>
                    }
                    {canAddTransactions &&
                    <NavLinkWrapperDiv>
                        <StyledNavLink to="/transactions">{t('header.transactions')}</StyledNavLink>
                    </NavLinkWrapperDiv>
                    }
                    {/*{canAddNews &&*/}
                    {/*<NavLinkWrapperDiv>*/}
                    {/*    <StyledNavLink to="/news/manage">{t('header.manage_news')}</StyledNavLink>*/}
                    {/*</NavLinkWrapperDiv>*/}
                    {/*}*/}
                </NavigationButtonsWrapper>
            </HeaderCenterWrapper>
            <HeaderSideWrapper style={{textAlign: isRtl ? 'left' : 'right', padding: '0 10px'}}>{
                firstLogin ?
                    <>
                        <div style={{cursor: 'default'}}>{firstLogin}</div>
                        <StyledActiveSpan>{t("header.set_new_password")}</StyledActiveSpan>
                    </> :
                    !loggedIn ?
                        <>
                            <StyledNavLink to="/login">{t('header.sign_in')}</StyledNavLink>
                            {" / "}
                            <StyledNavLink to="/register">{t('header.register')}</StyledNavLink>
                        </> :
                        <>
                            <div style={{cursor: 'default'}}>
                                {email}
                            </div>
                            <StyledSignOut
                                onClick={async () => await Auth.signOut()}>{t('header.sign_out')}</StyledSignOut>
                            {" / "}
                            <StyledNavLink to="/password/change">{t('header.change_password')}</StyledNavLink>
                        </>}
            </HeaderSideWrapper>
        </HeaderWrapper>
    );
};

export default Header;