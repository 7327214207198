import React from 'react';
import styled from 'styled-components';
import {darkYellow, mediumYellow} from "./BaseComponents";
import {useTranslation} from "react-i18next";
import {useCheckAppVersion} from "../hooks/useCheckAppVersion";

const FooterWrapper = styled.div`
  background: white;
  font-size: 10px;
  font-weight: bold;
  padding: 5px;
  text-align: center;
  color: ${mediumYellow};
  box-shadow: 0px -1px 6px #00000029;
  z-index: 9999;
  display: flex;
  justify-content: space-between;
`;

const FooterSide = styled.div`
  width: 300px;
`;

const StyledA = styled.a`
  color: ${mediumYellow};

  :hover {
    color: ${darkYellow};
  }
`;

const Footer = () => {
    const {currentVersion, currentMainHashRef, currentSubHashesRef} = useCheckAppVersion();
    const {t, i18n} = useTranslation();
    const isRtl = i18n.dir() === "rtl";

    return (
        <FooterWrapper>
            <FooterSide style={{textAlign: isRtl ? 'right' : 'left'}}>
                Version {currentVersion}{currentMainHashRef.current ? '.' : ''}{currentMainHashRef.current}
            </FooterSide>
            <div>
                {t('footer.copyright')}
            </div>
            <FooterSide style={{textAlign: isRtl ? 'left' : 'right'}}>
                {t('footer.created_by')}{' '}
                <StyledA href="mailto:info@guggrp.com">{t('footer.guggenheim_group')}</StyledA>
            </FooterSide>
        </FooterWrapper>
    );
};

export default Footer;